import { EditorMode } from '@/models/editorMode';
import { CustomMxEvent } from '@/sagas/editor.saga.constants';
import { MxEventObject, MxEvent, MxCell } from 'MxGraph';
import { createGridCellLayout } from '../../grid/GridCellLayout';
import { GridDiagram } from '../../grid/GridDiagram';
import { showRenameDialog } from '../../grid/SideEffects';
import { GridCell } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';
import { groupBy, filter } from 'lodash-es';
import { MessageDescriptor } from 'react-intl';
import { v4 as uuid } from 'uuid';
import messages from './PSDGrid.messages';
import {
    contentAvailableSymbols,
    firstColumnAvailableSymbols,
    firstRowAvailableSymbols,
} from './PSDGridInitial.config';
import { EdgeInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { ShapeStyle, SymbolType } from '@/models/Symbols.constants';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';

class PSDGrid extends GridDiagram {
    isRenderEdges: boolean = true;
    autoEdgeStyleName = 'autoEdge';
    public isGridValidTarget(target: MxCell, symbolId: string) {
        const otherAvailableSymbolIds = [
            PictureSymbolConstants.PICTURE_SYMBOL_ID,
            SymbolType.SHAPE,
            SymbolType.COMMENT,
            SymbolType.LABEL,
        ];
        if (otherAvailableSymbolIds.includes(symbolId)) {
            return true;
        }
        if (Object.values(ShapeStyle).includes(symbolId as ShapeStyle)) {
            return true;
        }

        const { gridRowPosition, gridColumnPosition } = this.getCellGridPosition(target);

        if (!gridRowPosition || !gridColumnPosition) {
            return false;
        }

        if (gridRowPosition === 1 && gridColumnPosition === 1) {
            return false;
        }

        if (gridColumnPosition === 1 && firstColumnAvailableSymbols.includes(symbolId)) {
            return true;
        }

        if (gridRowPosition === 1 && firstRowAvailableSymbols.includes(symbolId)) {
            return true;
        }

        if (gridColumnPosition > 1 && gridRowPosition > 1 && contentAvailableSymbols.includes(symbolId)) {
            return true;
        }

        return false;
    }

    private getCellGridPosition(cell: MxCell): {
        gridRowPosition: number | undefined;
        gridColumnPosition: number | undefined;
    } {
        try {
            const gridColumnPosition = this.graph.indexOfColumn(cell);
            const gridRowPosition = this.graph.indexOfRow(cell);

            return { gridRowPosition, gridColumnPosition };
        } catch (e) {
            return { gridRowPosition: undefined, gridColumnPosition: undefined };
        }
    }

    protected addLayout() {
        createGridCellLayout(this.graph);
    }

    protected addKeyBindings() {
        const { graph } = this;

        graph.getModel().addListener(CustomMxEvent.DROP_ELEMENT, (sender, event: MxEventObject) => {
            requestAnimationFrame(() => {
                this.renderEdges(event.properties.target);
            });
        });

        const doubleClickListener = (sender, event: MxEventObject) => {
            const targetCell = event.getProperty('cell');
            if (this.graph.mode !== EditorMode.Edit) {
                event.consume();

                return;
            }
            if (this.graph.isTable(targetCell) || this.graph.isTableCell(targetCell)) {
                event.consume();
            }

            const style = targetCell.getStyle();
            if (style.includes(this.headHorizontalStyle) || style.includes(this.headVerticalStyle)) {
                showRenameDialog(targetCell.getValue(), (value) => {
                    targetCell.setValue(value);
                    this.graph.refresh();
                });
            }
        };
        graph.addListener(MxEvent.DOUBLE_CLICK, doubleClickListener);
    }

    public renderEdges(source: MxCell) {
        if (!this.isRenderEdges) {
            return;
        }
        const { cells, rows, columns } = this.serialize();
        const firstRowId = rows[0]?.id;
        const firstColumnId = columns[0]?.id;

        const model = this.graph.getModel();

        const getPositionByCell = (tableCell: MxCell): GridCell | undefined => {
            const id = tableCell.getId();

            return cells.find((c) => {
                return c.id === id;
            });
        };

        const modelObjects = filter(model.cells, (cell: MxCell) => {
            return this.isSupportedCell(cell);
        });

        const filledTableCells = modelObjects.map((c) => {
            return getPositionByCell(c.getParent());
        });

        if (!filledTableCells.length) {
            return;
        }

        const byRowId = groupBy(filledTableCells, 'rowId');
        const byColumnId = groupBy(filledTableCells, 'columnId');

        const [sourceCell] = (source.children || []).filter(this.isSupportedCell);
        if (!sourceCell) {
            return;
        }
        this.clearEdges(sourceCell);

        const sourceGridCell = cells.find((c) => {
            return c.id === sourceCell?.parent?.getId();
        });

        const targetGridCell: GridCell | undefined = cells.find((c) => {
            return c.id === sourceCell.parent.getId();
        });

        if (!targetGridCell) {
            return;
        }
        const connectRow = (
            localSourceGridCell: GridCell | undefined = sourceGridCell,
            localTargetGridCell: GridCell | undefined = undefined,
        ) => {
            const gridTargetCell =
                localTargetGridCell ||
                byRowId[targetGridCell.rowId].find((r) => {
                    return r.columnId === firstColumnId;
                });

            const firstTargetCell = model.getCell(gridTargetCell?.id);
            const localSourceCell = model.getCell(localSourceGridCell?.id as string);

            if (this.isConnected(localSourceCell, firstTargetCell)) {
                return;
            }

            if (firstTargetCell?.children?.length && localSourceGridCell) {
                this.connectTableCellChildren(localSourceGridCell, gridTargetCell, messages.referTo);
            }
        };

        const connectColumn = (
            localSourceGridCell: GridCell | undefined = sourceGridCell,
            localTargetGridCell: GridCell | undefined = undefined,
        ) => {
            const gridTargetCell =
                localTargetGridCell ||
                byColumnId[targetGridCell.columnId].find((cell) => {
                    return cell.rowId === firstRowId;
                });

            const firstTargetCell = model.getCell(gridTargetCell?.id);
            const localSourceCell = model.getCell(localSourceGridCell?.id as string);

            if (this.isConnected(localSourceCell, firstTargetCell)) {
                return;
            }

            if (firstTargetCell?.children?.length && localSourceGridCell) {
                this.connectTableCellChildren(
                    localSourceGridCell,
                    gridTargetCell,
                    messages.consistOf,
                    ';horizontal=0;',
                );
            }
        };

        if (targetGridCell?.rowId === firstRowId) {
            const columnGridCells = byColumnId[targetGridCell.columnId];
            columnGridCells.forEach((t) => {
                if (t.rowId === firstRowId) {
                    return;
                }

                connectColumn(t, sourceGridCell);
            });

            return;
        }

        if (targetGridCell?.columnId === firstColumnId) {
            const rowGridCells = byRowId[targetGridCell.rowId];
            rowGridCells.forEach((t) => {
                if (t.columnId === firstColumnId) {
                    return;
                }

                connectRow(t, sourceGridCell);
            });

            return;
        }

        connectRow();
        connectColumn();
    }

    private isConnected(source: MxCell, target: MxCell): boolean {
        const connection = source?.edges?.find((edge) => {
            if (
                (edge.source === source && edge.target === target) ||
                (edge.source === target && edge.target === source)
            ) {
                return true;
            }

            return false;
        });

        return !!connection;
    }

    private clearEdges(cell) {
        const edges = (cell.edges || []).filter((edge) => {
            return edge.getStyle().includes(this.autoEdgeStyleName);
        });

        if (edges.length) {
            this.graph.removeCells(edges);
        }
    }

    private connectTableCellChildren(
        sourceGridCell: GridCell,
        targetGridCell: GridCell,
        title: MessageDescriptor,
        style: string = '',
    ) {
        const model = this.graph.getModel();
        const [sourceTableCell] = model.getCell(sourceGridCell.id).children;
        const [targetTableCell] = model.getCell(targetGridCell.id).children;
        const parent = this.graph.getDefaultParent();
        const intl = LocalesService.useIntl();

        const id = uuid();
        const edgeValue = new EdgeInstanceImpl({
            id,
            style,
            source: sourceGridCell.id,
            target: targetGridCell.id,
            name: intl.formatMessage(title),
            invisible: false,
        });

        const edge = this.graph.insertEdge(parent, id, edgeValue, sourceTableCell, targetTableCell);

        edge.setStyle(`${edge.getStyle()}${style};${this.autoEdgeStyleName}`);

        return edge;
    }

    private isSupportedCell(cell: MxCell): boolean {
        return cell.getValue()?.type === 'object';
    }

    getNewColumnTitle() {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(messages.newColumnConsistOf);
    }

    getNewRowTitle() {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(messages.newRowReferTo);
    }
}

export default PSDGrid;
