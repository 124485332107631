import { SimulationModelingContainer } from '../SimulationModeling/containers/SimulationModeling.container';
import { ContentLoadingPageTab } from './components/ContentLoadingPageTab/ContentLoadingPageTab.component';
import { EditorContainer } from '../Editor/containers/Editor.container';
import { ScriptEditorContainer } from '../Script/ScriptEditor/containers/ScriptEditor.container';
import { ScriptDashboardContainer } from '../ScriptDashboard/containers/ScriptDashboard.container';
import { WikiEditorContainer } from '../Wiki/containers/WikiEditor.container';
import UserDataEditingContainer from '../AdminTools/UserManagement/containers/UserDataEditing.container';
import { LicensesTool } from '../AdminTools/Licenses/LicensesTool.component';
import UserManagmentComponent from '../AdminTools/UserManagement/components/UserManagement.component';
import { withWindowResize } from '../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import LicenseOwnersEditingTabContainer from '../AdminTools/LicenseOwnersEditingTab/containers/LicenseOwnersEditingTab.container';
import AccessPermissionOwnersEditingTabContainer from '../AdminTools/AccessPermissionOwnersEditingTab/containers/AccessPermissionOwnersEditingTab.container';
import DBAccessOwnersEditingTabContainer from '../AdminTools/DBAccessOwnersEditingTab/containers/DBAccessOwnersEditingTab.container';
import { MethodologySetting } from '../AdminTools/Methodology/components/MethodologySettingPage/MethodologySetting.component';
import { EdgeTypeEditorContainer } from '../AdminTools/Methodology/containers/EdgeType/EdgeTypeEditor.container';
import { SymbolEditorTabContainer } from '../AdminTools/Methodology/containers/SymbolEditorTab.container';
import { SafetySettings } from '../AdminTools/SafetySettings/SafetySettings.component';
import PresetProfileComponent from '../AdminTools/Methodology/components/Presets/PresetProfile/PresetProfileTab/PresetProfileTab.component';
import { Monitoring } from '../AdminTools/Monitoring/components/Monitoring.component';
import { DbSearch } from '../Search/DbSearch/DbSearch.component';
import { ImportVisioContainer } from '../Import/Visio/containers/ImportVisio.container';
import { WorkSpaceTabTypes } from './WorkSpaceTabTypesEnum';
import { ScriptScheduler } from '../MainMenu/components/ScriptScheduler/ScriptScheduler.component';
import PrincipalServerProfileOwnersEditingTabContainer from '../AdminTools/PrincipalServerProfileOwnersEditingTabContainer/containers/PrincipalServerProfileOwnersEditingTab.container';
import ObjectTypeEditorComponent from '../AdminTools/Methodology/components/Presets/ObjectType/ObjectTypeEditor.component';
import { PresetEditorComponent } from '../AdminTools/Methodology/components/Presets/PresetEditor.component';
import ModelTypeEditorComponent from '../AdminTools/Methodology/components/Presets/ModelType/ModelTypeEditor.component';
import ImportAris from '../Import/Aris/components/ImportAris/ImportAris';
import { Spreadsheet } from '../Spreadsheet/Spreadsheet.component';
import { FolderTypeEditorTab } from '../AdminTools/Methodology/components/Presets/FolderType/FolderTypeEditorTab/FolderTypeEditorTab.component';
import { PresetCopy } from '../AdminTools/Methodology/components/Presets/PresetCopy.component';
import KanbanModelTypeEditor from '../AdminTools/Methodology/components/Presets/KanbanModelType/KanbanModelTypeEditor.component';
import { KanbanCardEditor } from '../AdminTools/Methodology/components/Presets/ObjectType/KanbanCardEditor/KanbanCardEditor.component';
import { HomePageTab } from './components/HomePageTab/HomePageTab.component';
import { EventAccess } from '../AdminTools/EventAccessSettings/EventAccess.component';
import { Kanban } from '../Kanban/Kanban.component';
import { ChangeEntityTypeTab } from '../ChangeEntityType/ChangeEntityTypeTab.component';
import { SystemPropertiesComponent } from '../AdminTools/ServerSettings/SystemProperties.component';
import { DashboardEditor } from '../Dashboard/DashboardEditor.component';
import { ApprovalTab } from '../AdminTools/ApprovalTab/ApprovalTab.component';
import { NotificationTemplates } from '../AdminTools/NotificationTemplates/NotificationTemplates.component';
import { GroupDataEditingTab } from '../AdminTools/GroupDataEditingTab/componets/GroupDataEditingTab.component';
import { SearchDeletedElementsTab } from '../SearchDeletedElementsTab/SearchDeletedElementsTab.component';
import { CompareModels } from '../CompareModels/CompareModels.component';
import { MyApprovalsTab } from '../MyApprovalsTab/MyApprovalsTab.component';
import { MatrixEditor } from '../Matrix/MatrixEditor/MatrixEditor.component';
import { GraphicalComparison } from '../CompareModels/GraphicalComparison.component';
import { UserAccount } from '../UserAccountTab/UserAccountTab.component';
import MatrixModelTypeEditorComponent from '../AdminTools/Methodology/components/Presets/MatrixModelType/MatrixModelTypeEditor.component';

export const METHODOLOGY_SETTINGS_TYPES_ARR = [
    WorkSpaceTabTypes.METHODOLOGY_SETTINGS,
    WorkSpaceTabTypes.ADD_PRESET_TAB,
    WorkSpaceTabTypes.COPY_PRESET_TAB,
    WorkSpaceTabTypes.EDIT_MODEL_TYPE_TAB,
    WorkSpaceTabTypes.EDIT_OBJECT_TYPE_TAB,
    WorkSpaceTabTypes.EDIT_EDGE_TYPE_TAB,
    WorkSpaceTabTypes.EDIT_SYMBOL_TAB,
    WorkSpaceTabTypes.EDIT_FOLDER_TYPE_TAB,
    WorkSpaceTabTypes.EDIT_EDGE_TYPE_TAB,
    WorkSpaceTabTypes.EDIT_PRESET_PROFILE_TAB,
    WorkSpaceTabTypes.SIMULATION_MODELING,
    WorkSpaceTabTypes.EDIT_KANBAN_MODEL_TYPE_TAB,
    WorkSpaceTabTypes.EDIT_KANBAN_CARD_TYPE_TAB,
    WorkSpaceTabTypes.CHANGE_ENTITY_TYPE,
];

export const workSpaceTabComponents: {} = {
    [WorkSpaceTabTypes.HOME_PAGE_TAB]: HomePageTab,
    [WorkSpaceTabTypes.CONTENT_LOADING_PAGE_TAB]: ContentLoadingPageTab,
    [WorkSpaceTabTypes.EDITOR]: EditorContainer,
    [WorkSpaceTabTypes.SCRIPT_EDITOR]: ScriptEditorContainer,
    [WorkSpaceTabTypes.SCRIPT_DASHBOARD]: ScriptDashboardContainer,
    [WorkSpaceTabTypes.SCRIPT_SCHEDULER]: ScriptScheduler,
    [WorkSpaceTabTypes.WIKI_EDITOR]: WikiEditorContainer,
    [WorkSpaceTabTypes.MARTIX_EDITOR]: MatrixEditor,
    [WorkSpaceTabTypes.USERDATA_EDITING_TAB]: UserDataEditingContainer,
    [WorkSpaceTabTypes.SAFETY]: withWindowResize(SafetySettings),
    [WorkSpaceTabTypes.LICENSES]: LicensesTool,
    [WorkSpaceTabTypes.SERVER_SETTINGS]: SystemPropertiesComponent,
    [WorkSpaceTabTypes.USER_MANAGMENT]: UserManagmentComponent,
    [WorkSpaceTabTypes.GROUPDATA_EDITING_TAB]: GroupDataEditingTab,
    [WorkSpaceTabTypes.LICENSEOWNERS_EDITING_TAB]: LicenseOwnersEditingTabContainer,
    [WorkSpaceTabTypes.ACCESS_PERMISSION_OWNERS_EDITING_TAB]: AccessPermissionOwnersEditingTabContainer,
    [WorkSpaceTabTypes.DBACCESS_OWNERS_EDITING_TAB]: DBAccessOwnersEditingTabContainer,
    [WorkSpaceTabTypes.PRINCIPAL_SERVER_PROFILE_OWNERS_EDITING_TAB]: PrincipalServerProfileOwnersEditingTabContainer,
    [WorkSpaceTabTypes.MONITORING_TAB]: Monitoring,
    [WorkSpaceTabTypes.DB_SEARCH]: DbSearch,
    [WorkSpaceTabTypes.DB_SEARCH_DELETED_ELEMENTS]: SearchDeletedElementsTab,
    [WorkSpaceTabTypes.SPREADSHEET]: Spreadsheet,
    [WorkSpaceTabTypes.EVENT_ACCESS]: EventAccess,
    [WorkSpaceTabTypes.KANBAN]: Kanban,
    [WorkSpaceTabTypes.DASHBOARD]: DashboardEditor,
    [WorkSpaceTabTypes.APPROVALS_TAB]: withWindowResize(ApprovalTab),
    [WorkSpaceTabTypes.NOTIFICATIONS]: withWindowResize(NotificationTemplates),
    [WorkSpaceTabTypes.USER_ACCOUNT]: UserAccount,

    /* Methodology settings */
    [WorkSpaceTabTypes.METHODOLOGY_SETTINGS]: MethodologySetting,
    [WorkSpaceTabTypes.ADD_PRESET_TAB]: PresetEditorComponent,
    [WorkSpaceTabTypes.COPY_PRESET_TAB]: PresetCopy,
    [WorkSpaceTabTypes.EDIT_MODEL_TYPE_TAB]: ModelTypeEditorComponent,
    [WorkSpaceTabTypes.EDIT_MATRIX_MODEL_TYPE_TAB]: MatrixModelTypeEditorComponent,
    [WorkSpaceTabTypes.EDIT_OBJECT_TYPE_TAB]: ObjectTypeEditorComponent,
    [WorkSpaceTabTypes.EDIT_EDGE_TYPE_TAB]: EdgeTypeEditorContainer,
    [WorkSpaceTabTypes.EDIT_SYMBOL_TAB]: SymbolEditorTabContainer,
    [WorkSpaceTabTypes.EDIT_PRESET_PROFILE_TAB]: PresetProfileComponent,
    [WorkSpaceTabTypes.SIMULATION_MODELING]: SimulationModelingContainer,
    [WorkSpaceTabTypes.IMPORT_VISIO]: ImportVisioContainer,
    [WorkSpaceTabTypes.IMPORT_ARIS]: ImportAris,
    [WorkSpaceTabTypes.EDIT_FOLDER_TYPE_TAB]: FolderTypeEditorTab,
    [WorkSpaceTabTypes.EDIT_KANBAN_MODEL_TYPE_TAB]: KanbanModelTypeEditor,
    [WorkSpaceTabTypes.EDIT_KANBAN_CARD_TYPE_TAB]: KanbanCardEditor,
    /* Entity Type */
    [WorkSpaceTabTypes.CHANGE_ENTITY_TYPE]: ChangeEntityTypeTab,
    [WorkSpaceTabTypes.COMPARE_MODELS]: CompareModels,
    [WorkSpaceTabTypes.GRAPHICAL_COMPARISON]: GraphicalComparison,

    [WorkSpaceTabTypes.MY_APPROVALS]: withWindowResize(MyApprovalsTab),
};
