import {
    MATRIX_SELECT_HEADER_CELLS,
    MATRIX_CLEAR_SELECTED_CELLS,
    MATRIX_SELECT_CELLS,
    MATRIX_PAST_OBJECTS,
    MATRIX_GET_EDGES_LIST,
    MATRIX_ADD_CELL_DATA,
    MATRIX_DELETE_EDGE_DEFINITION,
    MATRIX_START_LOADING_CELL_DATA,
    MATRIX_HANDLE_DECOMPOSITION_CLICK,
    MATRIX_DELETE_OBJECT_HEADERS,
    MATRIX_COPY_LANES,
} from '@/modules/Matrix/actionTypes/matrixEditor.actionTypes';

import {
    TDeleteMatrixObjectHeaders,
    TMatrixAddCellDataAction,
    TMatrixClearSelectedCellsAction,
    TMatrixCopyLanesAction,
    TMatrixDecompositionIconClickAction,
    TMatrixDeleteEdgeDefinitionAction,
    TMatrixPastObjectsAction,
    TMatrixSelectCellsAction,
    TMatrixSelectHeaderCellsAction,
    TMatrixStartLoadingCellDataAction,
    TMatrixGetEdgesListAction,
} from './matrixEditor.actions.types';
import { ModelAssignment, Node, NodeId } from '@/serverapi/api';
import { HeaderType } from '../MatrixEditor/Matrix.types';
import { TMatrixCellData } from '../reducers/matrixEditor.reducer.types';

export const matrixSelectHeaderCells = (
    nodeId: NodeId,
    type: HeaderType,
    ids: string[],
): TMatrixSelectHeaderCellsAction => ({
    type: MATRIX_SELECT_HEADER_CELLS,
    payload: {
        matrixNodeId: nodeId,
        type,
        ids,
    },
});

export const matrixSelectCells = (nodeId: NodeId, ids: string[]): TMatrixSelectCellsAction => ({
    type: MATRIX_SELECT_CELLS,
    payload: {
        matrixNodeId: nodeId,
        ids,
    },
});

export const matrixClearSelectedCells = (nodeId: NodeId): TMatrixClearSelectedCellsAction => ({
    type: MATRIX_CLEAR_SELECTED_CELLS,
    payload: {
        matrixNodeId: nodeId,
    },
});

export const matrixPastObjects = (nodeId: NodeId): TMatrixPastObjectsAction => ({
    type: MATRIX_PAST_OBJECTS,
    payload: {
        matrixNodeId: nodeId,
    },
});

export const matrixCopyLanes = (nodeId: NodeId): TMatrixCopyLanesAction => ({
    type: MATRIX_COPY_LANES,
    payload: {
        matrixNodeId: nodeId,
    },
});

export const matrixGetEdgesList = (matrixNodeId: NodeId, cellId: string): TMatrixGetEdgesListAction => ({
    type: MATRIX_GET_EDGES_LIST,
    payload: {
        matrixNodeId,
        cellId,
    },
});

export const matrixStartLoadingCellData = (nodeId: NodeId): TMatrixStartLoadingCellDataAction => ({
    type: MATRIX_START_LOADING_CELL_DATA,
    payload: {
        matrixNodeId: nodeId,
    },
});

export const matrixAddCellData = (
    nodeId: NodeId,
    cellId: string,
    cellData: TMatrixCellData,
): TMatrixAddCellDataAction => ({
    type: MATRIX_ADD_CELL_DATA,
    payload: {
        matrixNodeId: nodeId,
        cellId,
        cellData,
    },
});

export const matrixDeleteEdgeDefinition = (
    matrixNodeId: NodeId,
    deleteNodeId: NodeId,
    cellId: string,
): TMatrixDeleteEdgeDefinitionAction => ({
    type: MATRIX_DELETE_EDGE_DEFINITION,
    payload: {
        matrixNodeId,
        deleteNodeId,
        cellId,
    },
});

export const matrixDecompositionIconClick = (
    nodeId: NodeId,
    edgeDefinitionId: string,
    modelAssignments: ModelAssignment[],
): TMatrixDecompositionIconClickAction => ({
    type: MATRIX_HANDLE_DECOMPOSITION_CLICK,
    payload: {
        matrixNodeId: nodeId,
        edgeDefinitionId,
        modelAssignments,
    },
});

export const deleteMatrixObjectHeaders = (nodes: Node[]): TDeleteMatrixObjectHeaders => ({
    type: MATRIX_DELETE_OBJECT_HEADERS,
    payload: {
        nodes,
    },
});
