import { Button, Tooltip } from 'antd';
import React, { FC } from 'react';
import theme from './MatrixEditorToolbar.scss';
import menuItemTheme from '../items/MenuItem.scss';
import messages from '../../messages/CommonToolbar.messages';
import classnames from 'classnames';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { useIntl } from 'react-intl';
import icReadMode from '@/resources/icons/ic-read-mode.svg';
import icEditMode from '@/resources/icons/ic-edit-mode.svg';
import icSettingsMatrix from '@/resources/icons/ic-settingmatrix.svg';
import icRefreshMatrix from '@/resources/icons/ic-update.svg';
import icPaste from '@/resources/icons/insert.svg';
import icCopy from '@/resources/icons/copy.svg';
import { EditorMode } from '@/models/editorMode';
import { TMatrixEditorToolbarProps } from './MatrixEditorToolbar.types';
import { useDispatch, useSelector } from 'react-redux';
import { matrixEditorModeChangedAction, refreshMatrix } from '@/modules/Matrix/actions/matrix.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { isCompact } from '@/selectors/generalMenu.selectors';
import { MatrixEditorSelectors } from '@/modules/Matrix/selectors/matrixEditor.selectors';
import { matrixCopyLanes, matrixPastObjects } from '@/modules/Matrix/actions/matrixEditor.actions';

export const MatrixEditorToolbar: FC<TMatrixEditorToolbarProps> = (props) => {
    const { nodeId } = props;

    const activeWorkspaceTab = useSelector(TabsSelectors.getActiveTab);
    const isActiveScheme = !!activeWorkspaceTab;
    const compact = useSelector(isCompact);
    const isCopyEnabled = useSelector(MatrixEditorSelectors.isCopyEnabled(nodeId));
    const isPastEnabled = useSelector(MatrixEditorSelectors.isPastEnabled(nodeId));

    const intl = useIntl();
    const dispatch = useDispatch();

    const onModeChange = (mode: EditorMode) => {
        dispatch(matrixEditorModeChangedAction(mode));
    };

    const openSettings = () => {
        dispatch(openDialog(DialogType.MATRIX_SETTINGS_DIALOG, props));
    };

    const refreshMatrixHandler = () => {
        dispatch(refreshMatrix(nodeId));
    };

    const copy = () => {
        dispatch(matrixCopyLanes(nodeId));
    };

    const past = () => {
        dispatch(matrixPastObjects(nodeId));
    };

    const isReadMode = activeWorkspaceTab?.mode === EditorMode.Read;
    const editModeTooltipeTitle = isReadMode
        ? intl.formatMessage(messages.toEditMode)
        : intl.formatMessage(messages.toReadMode);

    const className = classnames(menuItemTheme.container, {
        [menuItemTheme.container_compact]: compact,
        [menuItemTheme.toolbar_disabled]:
            activeWorkspaceTab === undefined ||
            (activeWorkspaceTab && activeWorkspaceTab.type === 'HomePageTab') ||
            activeWorkspaceTab.type === 'ContentLoadingPageTab',
    });
    const groupClassName = classnames(menuItemTheme.group, 'group');

    const handleModeChange = () => {
        onModeChange(isReadMode ? EditorMode.Edit : EditorMode.Read);
    };

    const showTooltip = isActiveScheme && !isReadMode;
    const disableCopyBtn = isReadMode || !isCopyEnabled;
    const disablePastBtn = isReadMode || !isPastEnabled;

    return (
        <div className={className}>
            <div className={groupClassName}>
                <div className={!compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        title={isActiveScheme ? editModeTooltipeTitle : ''}
                    >
                        <Button className={theme.modeButton} onClick={handleModeChange}>
                            <Icon spriteSymbol={isReadMode ? icReadMode : icEditMode} />
                        </Button>
                    </Tooltip>
                </div>
            </div>

            <div className={menuItemTheme.divider} />
            <div className={menuItemTheme.group}>
                <span className={!compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        title={showTooltip ? intl.formatMessage(messages.openSettings) : ''}
                    >
                        <Button onClick={openSettings} className={theme.matrixSettingsButton} disabled={isReadMode}>
                            <Icon spriteSymbol={icSettingsMatrix} disabled={isReadMode} />
                        </Button>
                    </Tooltip>
                </span>

                <span className={!compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        title={showTooltip ? intl.formatMessage(messages.refreshMatrix) : ''}
                    >
                        <Button
                            onClick={refreshMatrixHandler}
                            className={theme.refreshMatrixButton}
                            disabled={isReadMode}
                        >
                            <Icon spriteSymbol={icRefreshMatrix} disabled={isReadMode} />
                        </Button>
                    </Tooltip>
                </span>
            </div>
            <div className={menuItemTheme.group}>
                <span className={!compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        title={showTooltip && isPastEnabled ? intl.formatMessage(messages.paste) : ''}
                    >
                        <Button onClick={past} className={theme.commonButton} disabled={disablePastBtn}>
                            <Icon spriteSymbol={icPaste} disabled={disablePastBtn} />
                        </Button>
                    </Tooltip>
                </span>
                <span className={!compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        title={showTooltip && isCopyEnabled ? intl.formatMessage(messages.copy) : ''}
                    >
                        <Button onClick={copy} className={theme.commonButton} disabled={disableCopyBtn}>
                            <Icon spriteSymbol={icCopy} disabled={disableCopyBtn} />
                        </Button>
                    </Tooltip>
                </span>
            </div>
        </div>
    );
};
