import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { DiagramElement, MatrixLane, MatrixNode, NodeId } from '@/serverapi/api';
import { MatrixSelectors } from './matrix.selectors';
import { getSelectedLanesWithChildren } from '@/modules/Matrix/utils/Matrix.utils';
import { getCopiedElements } from '../../../selectors/editor.selectors';
import { HeaderType } from '../MatrixEditor/Matrix.types';
import { TMatrixEditorState, TMatrixCellData, TSelectedHeadersCells } from '../reducers/matrixEditor.reducer.types';

const matrixEditorStateSelector = (state: TRootState): TMatrixEditorState => state.matrixEditor;

export namespace MatrixEditorSelectors {
    export const getSelectedHeaderCells = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, TSelectedHeadersCells>(
            matrixEditorStateSelector,
            (state) => state.get(nodeId)?.selectedHeaderCells || { type: HeaderType.column, ids: [] },
        );

    export const getSelectedCells = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, string[]>(
            matrixEditorStateSelector,
            (state) => state.get(nodeId)?.selectedCells || [],
        );

    export const isMatrixUnsaved = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(
            matrixEditorStateSelector,
            (state) => !!state.get(nodeId)?.unsaved,
        );

    export const isCopyEnabled = (nodeId: NodeId) =>
        createSelector<TRootState, TSelectedHeadersCells, MatrixNode | undefined, boolean>(
            getSelectedHeaderCells(nodeId),
            MatrixSelectors.byId(nodeId),
            (selectedHeadersCells, matrix) => {
                if (!matrix?.data2) return false;

                const lanes = selectedHeadersCells.type === HeaderType.row ? matrix.data2.rows : matrix.data2.columns;
                const lastFilledIndex = lanes.findLastIndex((lane) => lane.text);
                const filledLanes = lanes.slice(0, lastFilledIndex + 1);
                const lanesToCopyWithChildren: MatrixLane[] = getSelectedLanesWithChildren(
                    selectedHeadersCells.ids,
                    filledLanes,
                    true,
                );

                return !!lanesToCopyWithChildren.length;
            },
        );

    export const isPastEnabled = (nodeId: NodeId) =>
        createSelector<
            TRootState,
            TSelectedHeadersCells,
            MatrixLane[] | DiagramElement[],
            MatrixNode | undefined,
            boolean
        >(
            getSelectedHeaderCells(nodeId),
            getCopiedElements,
            MatrixSelectors.byId(nodeId),
            (selectedHeaderCells, copiedElements, matrix) => {
                if (!matrix?.data2) return false;

                const isAutomatic =
                    (matrix.data2.rowSettings.isAutomatic && selectedHeaderCells.type === HeaderType.row) ||
                    (matrix.data2.columnSettings.isAutomatic && selectedHeaderCells.type === HeaderType.column);

                return !!selectedHeaderCells.ids.length && !!copiedElements.length && !isAutomatic;
            },
        );

    export const getCellData = (nodeId: NodeId, cellId: string) =>
        createSelector<TRootState, TMatrixEditorState, TMatrixCellData | undefined>(
            matrixEditorStateSelector,
            (state) => state.get(nodeId)?.cellsData?.[cellId],
        );

    export const isLoadingCellData = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixEditorState, boolean>(
            matrixEditorStateSelector,
            (state) => !!state.get(nodeId)?.isLoadingCellData,
        );
}
