import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './RenameObjectDialog.messages';
import theme from './RenameObjectDialog.scss';
import { TRenameObjectDialogProps } from './RenameObjectDialog.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

const objectNameValidator = (value: string = '') => value.trim().length <= 100;

const antdObjectNameValidator = (_, val: string) => (objectNameValidator(val) ? Promise.resolve() : Promise.reject());

export function RenameObjectDialog(props: TRenameObjectDialogProps) {
    const { open, onClose, onSubmit, objectDefinition, value } = props;
    const [title, setTitle] = useState(value);
    const intl = useIntl();
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'rename-window_cancel_button',
                },
                {
                    key: 'ok',
                    onClick: () => onSubmit(objectDefinition, title),
                    value: intl.formatMessage(messages.submit),
                    visualStyle: 'primary',
                    dataTest: 'rename-window_rename_button',
                    disabled: !title || !objectNameValidator(title),
                },
            ]}
        />
    );

    return (
        <>
            <Dialog footer={footer} onCancel={onClose} open={open} width="480px">
                <Form autoComplete="off" className={theme.formContainer}>
                    <Form.Item
                        name="object-title"
                        label={intl.formatMessage(messages.label)}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage(messages.errorMessage),
                            },
                            {
                                required: true,
                                message: intl.formatMessage(messages.excessLengthMessage),
                                validator: antdObjectNameValidator,
                            },
                        ]}
                        initialValue={title}
                    >
                        <Input
                            data-test="rename-window_element-name_input"
                            disabled={false}
                            placeholder={intl.formatMessage(messages.placeholder)}
                            value={title}
                            name="object-title"
                            autoFocus
                            onChange={(e) => setTitle(e.target.value.trim())}
                        />
                    </Form.Item>
                </Form>
            </Dialog>
        </>
    );
}
