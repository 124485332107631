import type { MxCell, MxCellState, MxMouseEvent } from '../mxgraph';
import type { Comment } from '@/serverapi/api';
import type { BPMMxGraph } from '../bpmgraph';
import { MxDictionary, MxEvent, MxGraphHandler, MxUtils, MxEventObject, MxConstants } from '../mxgraph';
import { convertImportedSvgToBase64 } from '../../utils/image.utils';
import formatIcon from '../../resources/icons/format-by-sample.svg';
import { getHalfIntersectingCells } from '../../utils/object';
import { CommentMarker } from '@/models/bpm/bpm-model-impl';
import { CustomMxEvent } from '@/sagas/editor.saga.constants';
import { BPMMxCellHighlight } from './BPMMxCellHighlight';
import { isCommentCell, pointToPercent } from '@/utils/bpm.mxgraph.utils';
import { getEdges } from '../util/BPMMxGraphHandler.utils';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolManager.class';

export class BPMMxGraphHandler extends MxGraphHandler {
    guidesEnabled: boolean = true;
    removeEmptyParents: boolean;
    graph: BPMMxGraph;

    getInitialCellForEvent(me: MxMouseEvent): MxCell | null {
        if (me.getState()?.cell) {
            const initCell = me.getState().cell;
            if (isCommentCell(initCell)) return initCell;
            const cell = this.graph.getCellAt(me.graphX, me.graphY, initCell);
            if (isCommentCell(cell)) return cell;
        }

        return super.getInitialCellForEvent(me);
    }

    mouseDown(sender: BPMMxGraph, me: MxMouseEvent) {
        const { graph } = this;

        if (
            !me.isConsumed() &&
            this.isEnabled() &&
            graph.isEnabled() &&
            me.getState() != null &&
            !MxEvent.isMultiTouchEvent(me.getEvent())
        ) {
            const cell = this.getInitialCellForEvent(me);

            if (!cell) {
                return;
            }

            if (isCommentCell(cell)) {
                const selectedCells = graph.getSelectionCells();
                const selectedCommentCells = selectedCells.filter((selectedCell) => isCommentCell(selectedCell));
                graph.setSelectionCells(selectedCommentCells);
            }

            this.delayedSelection = this.isDelayedSelection(cell, me);
            this.cell = null;

            if (this.isMoveEnabled() || (isCommentCell(cell) && graph.isCellMovable(cell))) {
                const { model } = graph;
                const geo = model.getGeometry(cell);

                if (
                    graph.isCellMovable(cell) &&
                    (!model.isEdge(cell) ||
                        graph.getSelectionCount() > 1 ||
                        (geo.points != null && geo.points.length > 0) ||
                        model.getTerminal(cell, true) == null ||
                        model.getTerminal(cell, false) == null ||
                        graph.allowDanglingEdges ||
                        (graph.isCloneEvent(me.getEvent()) && graph.isCellsCloneable()))
                ) {
                    this.start(cell, me.getX(), me.getY());
                } else if (this.delayedSelection) {
                    this.cell = cell;
                }

                this.cellWasClicked = true;
                this.consumeMouseEvent(MxEvent.MOUSE_DOWN, me);
            }
        }
    }

    mouseUp(sender: BPMMxGraph, me: MxMouseEvent) {
        if (!me.isConsumed()) {
            const { graph } = this;
            const initialCell = this.getInitialCellForEvent(me);

            if (this.cell != null && this.first != null && this.currentDx != null && this.currentDy != null) {
                const cell = me.getCell();

                if (
                    this.connectOnDrop &&
                    this.target == null &&
                    cell != null &&
                    graph.getModel().isVertex(cell) &&
                    graph.isCellConnectable(cell) &&
                    graph.isEdgeValid(null, this.cell, cell)
                ) {
                    graph.connectionHandler.connect(this.cell, cell, me.getEvent(), null);
                } else {
                    // clone сочетание cntrl + перемещение элемента мышью создает клон
                    /* const clone = graph.isCloneEvent(me.getEvent())
                        && graph.isCellsCloneable() && this.isCloneEnabled(); */
                    // запрещаем клонировать
                    const clone = false;

                    const { scale } = graph.getView();
                    const dx = this.roundLength(this.currentDx / scale);
                    const dy = this.roundLength(this.currentDy / scale);
                    const { target } = this;

                    if (graph.isSplitEnabled() && graph.isSplitTarget(target, this.cells, me.getEvent())) {
                        graph.splitEdge(target, this.cells, null, dx, dy);
                    } else {
                        this.moveCells(this.cells, dx, dy, clone, target, me.getEvent());
                    }
                }
            } else if (this.isSelectEnabled() && initialCell != null && me.getEvent()) {
                const pEvt: MouseEvent = me.getEvent() as MouseEvent;
                if (pEvt.button === 0) {
                    this.graph.selectCellForEvent(initialCell, me.getEvent());
                } else {
                    this.selectDelayed(me);
                }
            }
        }

        // Consumes the event if a cell was initially clicked
        if (this.cellWasClicked) {
            this.consumeMouseEvent(MxEvent.MOUSE_UP, me);
        }

        this.reset();
    }

    superMoveCells = (cells: MxCell[], dx: number, dy: number, clone: boolean, target: MxCell | null, evt?: Event) => {
        if (clone) {
            cells = this.graph.getCloneableCells(cells);
        }

        let parent: MxCell | undefined;

        // Removes cells from parent
        if (this.cell) {
            parent = this.graph.getModel().getParent(this.cell);
        }

        if (
            (parent &&
                target == null &&
                this.isRemoveCellsFromParent() &&
                this.shouldRemoveCellsFromParent(parent, cells, evt))
        ) {
            target = this.graph.getDefaultParent();
        }
  
        if (this.cell && ComplexSymbolManager.shouldChangeTarget(this.cell)) {
            target = this.graph.getModel().getParent(this.cell);
        }
        
        // Cloning into locked cells is not allowed
        clone = clone && !this.graph.isCellLocked(target || this.graph.getDefaultParent());

        this.graph.getModel().beginUpdate();

        try {
            const parents: MxCell[] = [];

            // Removes parent if all child cells are removed
            if (!clone && target != null && this.removeEmptyParents) {
                // Collects all non-selected parents
                const dict = new MxDictionary();

                for (let i = 0; i < cells.length; i++) {
                    dict.put(cells[i], true);
                }

                // LATER: Recurse up the cell hierarchy
                for (let i = 0; i < cells.length; i++) {
                    const par = this.graph.model.getParent(cells[i]);

                    if (par != null && !dict.get(par)) {
                        dict.put(par, true);
                        parents.push(par);
                    }
                }
            }
            // Passes all selected cells in order to correctly clone or move into
            // the target cell. The method checks for each cell if its movable.
            cells = this.graph.moveCells(cells, dx, dy, clone, target, evt);

            // Removes parent if all child cells are removed
            const temp: MxCell[] = [];

            for (let i = 0; i < parents.length; i++) {
                if (this.shouldRemoveParent(parents[i])) {
                    temp.push(parents[i]);
                }
            }

            this.graph.removeCells(temp, false);
            const allEdges = getEdges(cells);
            this.graph.refresh();
            this.graph.getModel().fireEvent(new MxEventObject(CustomMxEvent.UPDATE_CELLS_OVERLAYS, 'cells', allEdges));
        } finally {
            this.graph.getModel().endUpdate();
        }

        // Selects the new cells if cells have been cloned
        if (clone) {
            this.graph.setSelectionCells(cells);
        }

        if (this.isSelectEnabled() && this.scrollOnMove) {
            this.graph.scrollCellToVisible(cells[0]);
        }
    };

    moveCells(cells: MxCell[], dx: number, dy: number, clone: boolean, target: MxCell | null, evt?: Event) {
        this.superMoveCells(cells, dx, dy, clone, target, evt);

        const commentCells = cells.filter((cell) => isCommentCell(cell));

        if (this.cell && isCommentCell(this.cell) && commentCells.length) {
            const comments: Comment[] = commentCells.map((commentCell) => {
                const { comment } = commentCell.value as CommentMarker;
                const { x, y } = commentCell.geometry;

                if (!target) {
                    comment.elementOffsetX = undefined;
                    comment.elementOffsetY = undefined;
                    comment.elementId = undefined;
                } else {
                    const percentPoint = pointToPercent(commentCell.geometry, target);
                    comment.elementOffsetX = percentPoint.x;
                    comment.elementOffsetY = percentPoint.y;
                    comment.elementId = target?.getValue().id || target?.getId();
                }
      
                return {
                    ...comment,
                    x,
                    y,
                };
            });

            this.graph
                .getModel()
                .fireEvent(new MxEventObject(CustomMxEvent.CHANGE_COMMENTS_POSITION, 'comments', comments));
        }

        const objects = cells.filter((cell) => cell.value?.type === 'object');

        if (objects.length > 0) {
            this.graph.cleanupHiddenEdges(objects);
        }

        if (objects.length === 1) {
            this.graph.handleCellIntersection(objects[0]);
        }
    }

    mouseMove(sender: BPMMxGraph, evt: MxMouseEvent) {
        requestAnimationFrame(() => {
            this.superMouseMove(sender, evt);

            let cursor = sender.getCursorForMouseEvent(evt);

            if (cursor == null && sender.isEnabled() && sender.isCellMovable(evt.getCell())) {
                if (sender?.bpmMxGraphContext?.selectedCell && !sender.getModel().isEdge(evt.getCell())) {
                    const base64 = convertImportedSvgToBase64(formatIcon, 24, 26);
                    cursor = `url("data:image/svg+xml;base64,${base64}"), pointer`;
                }
            }

            if (cursor != null && evt.sourceState != null) {
                evt.sourceState.setCursor(cursor);
            }
        });
    }

    // этот обработчик события начала перемещения cell по холсту. Но не для перемещения cell из палитры на холст
    start(cell: MxCell, x: number, y: number) {
        super.start(cell, x, y);

        if (isCommentCell(cell)) {
            this.guide.horizontal = false;
            this.guide.vertical = false;
            this.guide.distance = false;
        }

        if (this.guidesEnabled) {
            this.guide.isStateIgnored = (state: MxCellState) => {
                if (state?.cell?.value?.type === 'label' || isCommentCell(state.cell)) {
                    return true;
                }

                if (this.isCellMoving(state.cell)) {
                    // это условие нужно чтобы не рисовать направляющие к копии cell которая остается на холсте когда мы начинаем перемещеать ее
                    return true;
                }
                // родительский метод решил не вызывать т.к. там очень запутанное условие
                // const parentIsStateIgnored = this.guide.isStateIgnored;
                // return parentIsStateIgnored(state);

                return false;
            };
        }

        this.graph.handleCellMoving(cell);
    }

    // возвращает ячейки для перемещения
    getCells(initialCell: MxCell): MxCell[] {
        let result = [initialCell];

        const { graph, delayedSelection } = this;

        if (!graph.isCellMovable(initialCell)) {
            return result;
        }

        let selectionCells = graph.getSelectionCells().filter((cell) => graph.isCellMovable(cell));

        // TODO добавить тесты для кейса с комментарием
        // если тянем за комментарий, тогда не нужно перетаскивать другие символы
        if (isCommentCell(initialCell)) {
            selectionCells = selectionCells.filter((cell) => isCommentCell(cell));
        }

        // если перемещаются более одной ячейки, то просто возвращаем все выделенные
        if (!delayedSelection || selectionCells.length <= 1) {
            // совместное перемещение ячеек пересекающих initialCell на 50% и более
            const allGraphCells = Object.values<MxCell>(this.graph.model.cells);

            // исключаются шейпы и прочие ячейки
            const allGraphObjectTypeVertices = allGraphCells.filter(
                (cell) => cell.isVertex() && cell.value?.type === 'object',
            );

            if (initialCell.value?.type === 'object' && initialCell.isVertex()) {
                const intersectingObjects = getHalfIntersectingCells(initialCell, allGraphObjectTypeVertices);

                if (intersectingObjects.length) {
                    const sortedObjects = MxUtils.sortCells([initialCell, ...intersectingObjects], true);
                    const initialCellId = initialCell.mxObjectId;
                    const initialCellIdx = sortedObjects.findIndex((cell) => cell.mxObjectId === initialCellId);
                    // нижележащие исключаются
                    const overlappingObjectsExceptUnder = sortedObjects.slice(initialCellIdx + 1);

                    result = [...result, ...overlappingObjectsExceptUnder];
                }
            }
        } else {
            result = selectionCells;
        }

        // TODO написать тесты для случая, когда тянем за label при выделенных других ячейках
        result = ComplexSymbolManager.getMovableCells(result);

        return result;
    }

    isValidDropTarget(target: MxCell | null, me: MouseEvent) {
        if (!target?.isEdge() && this.cell && isCommentCell(this.cell)) {
            return true;
        }

        return super.isValidDropTarget(target, me);
    }

    superMouseMove(sender, me) {
        const { graph } = this;

        if (
            !me.isConsumed() &&
            graph.isMouseDown &&
            this.cell != null &&
            this.first != null &&
            this.bounds != null &&
            !this.suspended
        ) {
            // Stops moving if a multi touch event is received
            if (MxEvent.isMultiTouchEvent(me.getEvent())) {
                this.reset();

                return;
            }

            let delta = this.getDelta(me);
            const tol = graph.tolerance;

            if (this.shape != null || this.livePreviewActive || Math.abs(delta.x) > tol || Math.abs(delta.y) > tol) {
                // Highlight is used for highlighting drop targets
                if (this.highlight == null) {
                    this.highlight = new BPMMxCellHighlight(this.graph, MxConstants.DROP_TARGET_COLOR, 3);
                }

                const clone = graph.isCloneEvent(me.getEvent()) && graph.isCellsCloneable() && this.isCloneEnabled();
                const gridEnabled = graph.isGridEnabledEvent(me.getEvent());
                const cell = me.getCell();
                let hideGuide = true;
                let target: MxCell | null = null;
                this.cloning = clone;

                if ((graph.isDropEnabled() || isCommentCell(this.cell)) && this.highlightEnabled) {
                    // Contains a call to getCellAt to find the cell under the mouse
                    target = graph.getDropTarget(this.cells, me.getEvent(), cell, clone);
                }

                let state = graph.getView().getState(target);
                let highlight = false;

                if (state != null && (clone || this.isValidDropTarget(target, me))) {
                    if (this.target !== target) {
                        this.target = target;
                        this.setHighlightColor(MxConstants.DROP_TARGET_COLOR);
                    }

                    highlight = true;
                    
                } else {
                    this.target = null;

                    if (
                        this.connectOnDrop &&
                        cell != null &&
                        this.cells.length === 1 &&
                        graph.getModel().isVertex(cell) &&
                        graph.isCellConnectable(cell)
                    ) {
                        state = graph.getView().getState(cell);

                        if (state != null) {
                            const error = graph.getEdgeValidationError(null, this.cell, cell);
                            const color =
                                error == null ? MxConstants.VALID_COLOR : MxConstants.INVALID_CONNECT_TARGET_COLOR;
                            this.setHighlightColor(color);
                            highlight = true;
                        }
                    }
                }

                if (state != null && highlight) {
                    this.highlight.highlight(state);
                } else {
                    this.highlight.hide();
                }

                if (this.guide != null && this.useGuidesForEvent(me)) {
                    delta = this.guide.move(this.bounds, delta, gridEnabled, clone);
                    hideGuide = false;
                } else {
                    delta = this.graph.snapDelta(delta, this.bounds, !gridEnabled, false, false);
                }

                if (this.guide != null && hideGuide) {
                    this.guide.hide();
                }

                // Constrained movement if shift key is pressed
                if (graph.isConstrainedEvent(me.getEvent())) {
                    if (Math.abs(delta.x) > Math.abs(delta.y)) {
                        delta.y = 0;
                    } else {
                        delta.x = 0;
                    }
                }

                this.checkPreview();

                if (this.currentDx !== delta.x || this.currentDy !== delta.y) {
                    this.currentDx = delta.x;
                    this.currentDy = delta.y;
                    this.updatePreview();
                }
            }

            this.updateHint(me);
            this.consumeMouseEvent(MxEvent.MOUSE_MOVE, me);

            // Cancels the bubbling of events to the container so
            // that the droptarget is not reset due to an mouseMove
            // fired on the container with no associated state.
            MxEvent.consume(me.getEvent());
        } else if (
            (this.isMoveEnabled() || this.isCloneEnabled()) &&
            this.updateCursor &&
            !me.isConsumed() &&
            (me.getState() != null || me.sourceState != null) &&
            !graph.isMouseDown
        ) {
            let cursor = graph.getCursorForMouseEvent(me);

            if (cursor == null && graph.isEnabled() && graph.isCellMovable(me.getCell())) {
                if (graph.getModel().isEdge(me.getCell())) {
                    cursor = MxConstants.CURSOR_MOVABLE_EDGE;
                } else {
                    cursor = MxConstants.CURSOR_MOVABLE_VERTEX;
                }
            }

            // Sets the cursor on the original source state under the mouse
            // instead of the event source state which can be the parent
            if (cursor != null && me.sourceState != null) {
                me.sourceState.setCursor(cursor);
            }
        }
    }
}
