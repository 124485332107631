import { call, fork, put } from 'redux-saga/effects';
import { appSaga } from './app.saga';
import { presetProfileSaga } from './presetProfile.saga';
import { editorSagaActions, editorSagaInit, editorClearSelection } from './editor.saga';
import { simulationModelingSagaInit } from './simulationModeling.saga';
import { initTreeNodeContextMenuActionsSagaDialog } from './treeNodeProperties.saga';
import { symbolNavigatorSaga } from './createDiagramElement.saga';
import { modelDialogSagaInit } from './modelDialog.saga';
import { selectObjectSagaInit } from './selectObjectDefinitionDialog.saga';
import { treeSaga } from './tree.saga';
import { authorizationSaga } from './authorization.saga';
import { databaseSagaInit } from './database.saga';
import { serverListSaga } from './serverList.saga';
import { objectDefinitionSagaInit } from './objectDefinition.saga';
import { folderDialogSagaInit } from './folderDialog.saga';
import { editorPopupMenuSaga } from './editorPopupMenu.saga';
import { objectPropertyDialogInit } from './dialogProperty.saga';
import { navigatorPropertiesSaga } from './navigatorProperties.saga';
import { objectDecompositionSagaInit } from './decomposition.saga';
import { managePermissionsSaga } from './managePermissions.saga';
import { recentSaga } from './recent.saga';
import { favoritesSaga } from './favorites.saga';
import { objectDefinitionDialogSagaInit } from './objectDefinitionDialog.saga';
import { importDialogSagaInit } from './importDialog.saga';
import { scriptSaga } from './script.saga';
import { workspaceTabSaga } from './tab.saga';
import { choseSymbolForDragDialogSaga } from './choseSymbolForDragDialog.saga';
import { wikiSaga } from './wiki.saga';
import { scriptSelectDialogSagaInit } from './scriptSelectDialog.saga';
import { uploaderSaga } from './uploader.saga';
import { lockSaga } from './lock.saga';
import { processError } from '../actions/error.actions';
import { errorSagaInit } from './error.saga';
import { matrixSaga } from '../modules/Matrix/sagas/matrix.saga';
import { commentSaga } from './comment.saga';
import { nodeMoveSaga } from './nodeMove.saga';
import { usersSaga } from './users.saga';
import { groupsSaga } from './groups.saga';
import { licenseSaga } from './license.saga';
import { accesPermissionSaga } from './accessPermission.saga';
import { safetySaga } from './safety.saga';
import { notationSaga } from './notation.saga';
import { modelHistorySaga } from './model/modelHistorySaga';
import { initModelPropertyDialog } from './modelProperty.saga';
import { methodologySettingSagaInit } from './methodologySetting.saga';
import { imageSagaInit } from './image.saga';
import { systemPropertiesSaga } from './systemProperties.saga';
import { auditSaga } from './audit.saga';
import { loadModelSaga } from './model/loadModel.saga';
import { saveModelSagaInit } from './model/saveModel.saga';
import { appStartSaga } from './appStart.saga';
import { attributeTypeSagaInit } from './attributeType.saga';
import { monitoringSaga } from './monitoring.saga';
import { overlaySaga } from './overlay.saga';
import { openAttributeLinkSaga } from './openAttributeLink.saga';
import { searchSaga } from './search.saga';
import { importSaga } from './import.saga';
import { openNodeSaga } from './openNode.saga';
import { sessionSaga } from './sessions.saga';
import { fileSaga } from './file.saga';
import { exportNotationSaga } from './transferNotation.saga';
import { edgeSaga } from './edge.saga';
import { cloneModelSagaInit } from './model/cloneModel.saga';
import { aclSaga } from './acl.saga';
import { serverProfileSagaInit } from './serverProfile.saga';
import { principalServerProfileSagaInit } from './principalServerProfile.saga';
import { presetSettingsModelTypesSaga } from './presetSettings/presetSettingsModelTypes.saga';
import { deleteNodeSaga } from './deleteNode.saga';
import { importArisSaga } from './importAris.saga';
import { objectSagaInit } from './objectType.saga';
import { spreadsheetDialogSagaInit } from './spreadsheetDialog.saga';
import { spreadsheetSaga } from './spreadsheet.saga';
import { folderTypeSaga } from './folderType.saga';
import { modelLinkSaga } from './copyLink.saga';
import { tabsBusSaga } from './tabsBus.saga';
import { tabsBusSubscribeSaga } from './tabsBusSubscribe.saga';
import { presetImageSagaInit } from './presetSettings/presetImage.saga';
import { eventAccessSaga } from './eventAccess.saga';
import { kanbanSaga } from './kanban.saga';
import { edgeDefinitionSaga } from './edgeDefinition.saga';
import { changeEntityTypesSaga } from './changeEntityTypes.saga';
import { modelEventsSaga } from './model/modelEvents.saga';
import { atrributeSaga } from './attribute.saga';
import { scriptContextSaga } from './scriptContext.saga';
import { nodesSaga } from './nodes.saga';
import { externalSessionSaga } from './externalSession.saga';
import { wsConnectionSaga } from './wsConnection.saga';
import { renameObjectDialogSaga } from './renameObjectDialog.saga';
import { kanbanMethodologySettingSaga } from './kanbanMethodologySetting.saga';
import { selectEdgeSagaInit } from './selectEdgeDefinitionDialog.saga';
import { approvalSaga } from './approval.saga';
import { dashboardSaga } from './dashboard/dashboard.saga';
import { initEdgeManagementDialog } from './edgeManagement.saga';
import { eraseDeletedNodeSaga } from './eraseDeletedNode.saga';
import { restoreDeletedNodeDialogSaga } from './restoreDeletedNodeDialog.saga';
import { notificationTemplatesSaga } from './notificationTemplates.saga';
import { notificationsSaga } from './notifications.saga';
import { whiteboardSagaActions } from './whiteboard.saga';
import { messagesSaga } from './messages.saga';
import { checkBrowserSaga } from './checkBrowser.saga';
import { compareModelsSaga } from './model/compareModels.saga';
import { approvalTemplateSaga } from './approvalTemplates.saga';
import { searchDeletedElementsSaga } from './searchDeletedElements.saga';
import { querySelectAttributesTypesValues } from './querySelectAttributeTypeValues.saga';
import { scriptQueryParamsSaga } from './scriptQueryParams.saga';
import { matrixEditorSaga } from '../modules/Matrix/sagas/matrixEditor.saga';
import { userAccountSaga } from './userAccount.saga';
import { presetSettingsMatrixModelTypesSaga } from './presetSettings/presetSettingsMatrixModelType.saga';
import { editorPasteSaga } from './editorPaste.saga';
import { nodeStatisticsSaga } from './nodeStatistics.saga';
import { undoRedoSaga } from './undoRedo.saga';

function forkAutoRestarting(fn, ...args: any[]) {
    // tslint:disable-line no-any
    return fork(function* () {
        while (true) {
            try {
                yield call(fn, ...args);
            } catch (e) {
                try {
                    yield put(processError(e, ''));
                } catch (processingError) {
                    // tslint:disable:no-console
                    console.error(`Cycling error in root saga error processing found, primary error:
                        ${e}, error with processing it: ${processingError}.`);
                }
            }
        }
    });
}

export function* rootSaga() {
    yield forkAutoRestarting(wsConnectionSaga);
    yield forkAutoRestarting(serverProfileSagaInit);
    yield forkAutoRestarting(appSaga);
    yield forkAutoRestarting(editorSagaInit);
    yield forkAutoRestarting(editorClearSelection);
    yield forkAutoRestarting(loadModelSaga);
    yield forkAutoRestarting(saveModelSagaInit);
    yield forkAutoRestarting(cloneModelSagaInit);
    yield forkAutoRestarting(databaseSagaInit);
    yield forkAutoRestarting(modelDialogSagaInit);
    yield forkAutoRestarting(scriptSelectDialogSagaInit);
    yield forkAutoRestarting(folderDialogSagaInit);
    yield forkAutoRestarting(selectObjectSagaInit);
    yield forkAutoRestarting(selectEdgeSagaInit);
    yield forkAutoRestarting(editorSagaActions);
    yield forkAutoRestarting(whiteboardSagaActions);
    yield forkAutoRestarting(symbolNavigatorSaga);
    yield forkAutoRestarting(workspaceTabSaga);
    yield forkAutoRestarting(serverListSaga);
    yield forkAutoRestarting(treeSaga);
    yield forkAutoRestarting(deleteNodeSaga);
    yield forkAutoRestarting(nodeMoveSaga);
    yield forkAutoRestarting(authorizationSaga);
    yield forkAutoRestarting(objectDefinitionSagaInit);
    yield forkAutoRestarting(objectDefinitionDialogSagaInit);
    yield forkAutoRestarting(initEdgeManagementDialog);
    yield forkAutoRestarting(editorPopupMenuSaga);
    yield forkAutoRestarting(objectPropertyDialogInit);
    yield forkAutoRestarting(initModelPropertyDialog);
    yield forkAutoRestarting(initTreeNodeContextMenuActionsSagaDialog);
    yield forkAutoRestarting(navigatorPropertiesSaga);
    yield forkAutoRestarting(objectDecompositionSagaInit);
    yield forkAutoRestarting(managePermissionsSaga);
    yield forkAutoRestarting(recentSaga);
    yield forkAutoRestarting(favoritesSaga);
    yield forkAutoRestarting(importDialogSagaInit);
    yield forkAutoRestarting(scriptSaga);
    yield forkAutoRestarting(choseSymbolForDragDialogSaga);
    yield forkAutoRestarting(wikiSaga);
    yield forkAutoRestarting(matrixSaga);
    yield forkAutoRestarting(matrixEditorSaga);
    yield forkAutoRestarting(commentSaga);
    yield forkAutoRestarting(uploaderSaga);
    yield forkAutoRestarting(lockSaga);
    yield forkAutoRestarting(errorSagaInit);
    yield forkAutoRestarting(usersSaga);
    yield forkAutoRestarting(groupsSaga);
    yield forkAutoRestarting(licenseSaga);
    yield forkAutoRestarting(accesPermissionSaga);
    yield forkAutoRestarting(safetySaga);
    yield forkAutoRestarting(notationSaga);
    yield forkAutoRestarting(modelHistorySaga);
    yield forkAutoRestarting(modelLinkSaga);
    yield forkAutoRestarting(tabsBusSaga);
    yield forkAutoRestarting(tabsBusSubscribeSaga);
    yield forkAutoRestarting(methodologySettingSagaInit);
    yield forkAutoRestarting(simulationModelingSagaInit);
    yield forkAutoRestarting(imageSagaInit);
    yield forkAutoRestarting(systemPropertiesSaga);
    yield forkAutoRestarting(appStartSaga);
    yield forkAutoRestarting(auditSaga);
    yield forkAutoRestarting(attributeTypeSagaInit);
    yield forkAutoRestarting(monitoringSaga);
    yield forkAutoRestarting(searchSaga);
    yield forkAutoRestarting(overlaySaga);
    yield forkAutoRestarting(importSaga);
    yield forkAutoRestarting(importArisSaga);
    yield forkAutoRestarting(openNodeSaga);
    yield forkAutoRestarting(sessionSaga);
    yield forkAutoRestarting(fileSaga);
    yield forkAutoRestarting(exportNotationSaga);
    yield forkAutoRestarting(edgeSaga);
    yield forkAutoRestarting(presetProfileSaga);
    yield forkAutoRestarting(aclSaga);
    yield forkAutoRestarting(principalServerProfileSagaInit);
    yield forkAutoRestarting(presetSettingsModelTypesSaga);
    yield forkAutoRestarting(presetSettingsMatrixModelTypesSaga);
    yield forkAutoRestarting(objectSagaInit);
    yield forkAutoRestarting(presetImageSagaInit);
    yield forkAutoRestarting(spreadsheetDialogSagaInit);
    yield forkAutoRestarting(spreadsheetSaga);
    yield forkAutoRestarting(folderTypeSaga);
    yield forkAutoRestarting(eventAccessSaga);
    yield forkAutoRestarting(kanbanSaga);
    yield forkAutoRestarting(edgeDefinitionSaga);
    yield forkAutoRestarting(changeEntityTypesSaga);
    yield forkAutoRestarting(openAttributeLinkSaga);
    yield forkAutoRestarting(modelEventsSaga);
    yield forkAutoRestarting(atrributeSaga);
    yield forkAutoRestarting(scriptContextSaga);
    yield forkAutoRestarting(nodesSaga);
    yield forkAutoRestarting(externalSessionSaga);
    yield forkAutoRestarting(renameObjectDialogSaga);
    yield forkAutoRestarting(kanbanMethodologySettingSaga);
    yield forkAutoRestarting(approvalSaga);
    yield forkAutoRestarting(approvalTemplateSaga);
    yield forkAutoRestarting(dashboardSaga);
    yield forkAutoRestarting(eraseDeletedNodeSaga);
    yield forkAutoRestarting(restoreDeletedNodeDialogSaga);
    yield forkAutoRestarting(notificationTemplatesSaga);
    yield forkAutoRestarting(notificationsSaga);
    yield forkAutoRestarting(messagesSaga);
    yield forkAutoRestarting(checkBrowserSaga);
    yield forkAutoRestarting(searchDeletedElementsSaga);
    yield forkAutoRestarting(compareModelsSaga);
    yield forkAutoRestarting(querySelectAttributesTypesValues);
    yield forkAutoRestarting(scriptQueryParamsSaga);
    yield forkAutoRestarting(userAccountSaga);
    yield forkAutoRestarting(editorPasteSaga);
    yield forkAutoRestarting(nodeStatisticsSaga);
    yield forkAutoRestarting(undoRedoSaga);
}
