import { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import { apiBundle } from '../api/api-bundle';
import { setServerIdToNodeOriginal } from '@/utils/nodeId.utils';

export class WikiDaoService {
    public static async saveWiki(serverId: string, body: IWikiNode): Promise<IWikiNode> {
        if(body.source2){
            body.content = JSON.parse(body.source2);
        }
        const wiki = (await apiBundle(serverId).wiki.save({ body })) as IWikiNode;
        setServerIdToNodeOriginal(wiki, serverId);
        return wiki;
    }

    public static async getWikiById(serverId: string, repositoryId: string, wikiId: string): Promise<IWikiNode> {
        const wiki = (await apiBundle(serverId).wiki.get({ wikiId, repositoryId })) as IWikiNode;
        setServerIdToNodeOriginal(wiki, serverId);
        wiki.source2 = JSON.stringify(wiki.content);
        return wiki;
    }
}
